*, *:before, *:after {
    box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  background: white;
}

.main {
  padding: 2em 40px;
  padding-bottom: 8em;
  padding-top: 0;
  line-height: 1.5em;
}
h1 {
  margin: 0;
}

.subtitle {
  color: #555;
  font-weight: normal;
  margin-top: 15px;
}

p {
  line-height: 1.5em;
  font-size: 18px;
  max-width: 700px;
  color: #444;
}
.intro {
  flex: 1;
  margin-right: 50px;
}

.first {
  display: flex;
  margin: 40px 0;
}
.first-right {
  flex: 1;
}

.user-info {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 10px 30px;
  border-radius: 5px;
  background: rgba(0,0,0,0.1);
}
.user-info:hover {
  background: rgba(0,0,0,0.2);
}
.user-info .name {
  flex: 1;
  font-size: 18px;
  font-weight: 500;
}
.user-info .age {
  font-size: 12px;
  flex: 1;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.7);
}

.illustration .label {
  color: #999;
}
.viz {
  display: flex;
  max-width: 1024px;
  flex-direction: row;
}
.deduction {
  width: 150px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  flex-direction: column;
}
.deductionAmt {
  font-size: 28px;
  font-weight: bold;
}
.deductionLabel {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  color: #777;
}

.gift-list {
  border-top: solid rgba(0, 0, 0, 0.2) 1px;
}
.gift-list .gift-link {
  padding: 1em 20px;
  border-bottom: solid rgba(0, 0, 0, 0.2) 1px;
  cursor: pointer;
  display: block;
  color: white;
  text-decoration: none;
}
.gift-list .gift-link.selected {
  background: rgba(0, 0, 0, 0.2);
}

.copyright {
  font-size: 12px;
  color: #666;
  margin-top: 2em;
}

.gift-types {
  line-height: 1.5em;
  margin-top: 1em;
}

.loading-page {
  width: 100%;
  height: 100%;
  padding: 3em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media print { 
    body{
        background: white !important;
    }
    .main {
        padding-bottom: 0;
    }
}